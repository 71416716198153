import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';

import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { MyProfileService } from './my-profile.service';
import { PersonalInfoModel } from '../models/personal-info-model';
import { FirebaseAnalyticsService } from './firebase-analytics.service';
export const TOKEN_KEY = 'jwt-token';
const apiURLs = {
  root: environment.restApiUrl,
  login: '/token/',
  sso: '/autho/sso/post/',
  client: '/client/'
}

export interface LoginResponse {
  access: string;
  refresh: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<any>;
  private userData: any = new BehaviorSubject(null);

  constructor(
    private http: HttpClient, 
    private plt: Platform, 
    private router: Router,
    private myProfileService: MyProfileService, 
    private firebaseAnalytics: FirebaseAnalyticsService
  ) { 
    this.loadStoredToken();
  }

  loadStoredToken() {
    let platformObs = from(this.plt.ready());
 
    this.user = platformObs.pipe(
      switchMap(() => {
        return from(SecureStoragePlugin.get({ key: TOKEN_KEY })
          .then(
            token => {
              console.log(token)
              return token;
            }
          )
          .catch(
            err => {
              return null;
            }
          )
        );
      }),
      map(token => {
        console.log('token in storage:', token)
        if (token) {
          this.userData.next(token);
          return true;
        } else {
          return null;
        }
      })
    );
  }
 
  login(credentials: { username: string, password: string }) {
    console.log('sending login credentials:', credentials);
    return this.http.post<LoginResponse>(apiURLs.root + apiURLs.login, credentials).pipe(
      take(1),
      map(res => {
        // TODO Extract the JWT access or refresh
        return res.access;

      }),
      switchMap(token => {
        this.userData.next(token);
        let storageObs = from(SecureStoragePlugin.set({ key: TOKEN_KEY, value: token }));
        this.setAnalyticsID();
        return storageObs;
      }),
      switchMap(() => {
        // Now call the route to get the client status
        return this.http.get(apiURLs.root + apiURLs.client);
      }),
      map((clientData: any) => {
        // // Store the client status
        const clientStatus = clientData.client.status.toString();
        console.log('Client status:', clientStatus);

        // Store client status using Capacitor Preferences API
        Preferences.set({ key: 'client-status', value: clientStatus }).then(() => {
          Preferences.get({ key: 'client-status' }).then((result) => {
            console.log('Stored Client Status:', result.value);
          });
        });

        return clientStatus;
      })
    );
  }
 
  getUser() {
    return this.userData.getValue();
  }
 
  logout() {
    Preferences.remove({ key: 'client-status' }).then(() => {
      console.log('Client Status removed from storage');
    });
    SecureStoragePlugin.remove({ key: TOKEN_KEY }).then(() => {
      this.router.navigateByUrl('/authentication/login');
      this.userData.next(null);
    });

  }

  private setAnalyticsID() {
    this.myProfileService.getPersonalInfo().subscribe(
      (data: PersonalInfoModel) => {
        this.firebaseAnalytics.setUser(data.profile.unique_id);
        this.firebaseAnalytics.logEvent('login', { description: 'login to mobile app'});
      },
      err => {
        console.log(err)
      }
    );
  }

  sendSSOparams(data: any): Observable<any> {
    return this.http.post(apiURLs.root + apiURLs.sso, data, {responseType: 'text'});
  }
}
